import React from 'react'

export default function Footer({ clientData }) {
    return (
        <div className='flex justify-between items-center pt-10'>
            <div className=' text-[10px] font-bold'>
                {
                    clientData && clientData.consentDate ?
                        <p className=' font-bold text-[12px]'>DATE: {new Date(clientData.consentDate).toDateString()} </p>
                        :
                        <p>Date: DAY,DD MONTH YY</p>

                }
                <p>PLEASE STORE THIS DOCUMENT SAFELY</p>
            </div>
            {/* <div className=' text-[10px] font-bold'>
                <p>Page 1 of 2</p>
            </div> */}
            <div className=' text-[10px] font-bold'>
                <p>CONFIDENTIAL DOCUMENT</p>
                <p className=' text-red-500 underline text-right'>CLIENT COPY</p>
            </div>
        </div>
    )
}
