import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Host, ImageHost } from '../../Data'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Logo from "../Photos/logo.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToWords } from 'to-words';
import SignaturePad from '../Components/SignaturePad'


export default function Home() {
    const [clientData, setClientData] = useState(null)
    const location = useLocation()
    const path = location.search.split("?")[1]
    const [uploadSignatureMode, setUploadSignatureMode] = useState(false)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const toWords = new ToWords();

    const getUserInfo = async () => {
        try {
            if (path) {
                const res = await axios.get(`${Host}/api/clients/${path}`)
                setClientData(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getUserInfo()
    }, [path])

    return (
        <div className=' w-full bg-gray-100 min-h-screen py-10 pt-20'>
            {/* page 1 start */}
            <div className=' mx-auto w-[800px] print:w-full bg-white shadow pb-10'>
                {/* section 1 */}
                <div className=' px-14 print:px-20 -mt-10 break-after-page relative'>
                    <Header />
                    <div className=' w-full flex justify-center'>
                        <img src={Logo} alt="logo" className=' w-32' />
                    </div>
                    <div className=' text-center font-bold'>
                        <p className=''>CLIENT SERVICE AGREEMENT</p>
                        <p className=' text-sm italic'>FOR CONSULTANCY SERVICES</p>
                        <p className='my-14'>BETWEEN</p>
                        <p>GIC EDUCATION LIMITED</p>
                        <p className=' text-[9px] font-semibold'>Hereinafter referred to as the Consultant (which expression, unless repugnant to the subject or context, shall include its executors,administrators, legal representatives and assigns) of the Parties of the First Part.</p>

                        <p className=' mt-14 mb-14'>AND</p>
                    </div>
                    <div className=' grid grid-cols-2 gap-3 px-2'>
                        <div className=' flex items-center'>
                            <div className={`${path ? "w-28" : "w-40"}`}>
                                <span className=' text-[11px] font-bold'>NAME:</span>
                            </div>
                            {
                                clientData && clientData.name ?
                                    <span className=' font-semibold text-sm'>{clientData.name}</span>
                                    :
                                    <span className=' inline-block h-[1px] w-full  bg-black'></span>
                            }
                        </div>
                        <div className=' flex items-center'>
                            <div className={`${path ? "w-28" : "w-40"}`}>
                                <span className='text-[11px] font-bold'>PHONE NUMBER:</span>
                            </div>
                            {
                                clientData && clientData.phoneNumber ?
                                    <span className=' font-semibold  text-sm'>{clientData.phoneNumber}</span>
                                    :
                                    <span className=' inline-block h-[1px] w-full  bg-black'></span>
                            }
                        </div>
                        <div className=' flex items-center'>
                            <div className={`${path ? "w-28" : "w-40"}`}>
                                <span className='text-[11px] font-bold'>CLIENT ID:</span>
                            </div>
                            {
                                clientData && clientData.clientId ?
                                    <span className=' font-semibold  text-sm'>{clientData.clientId}</span>
                                    :
                                    <span className=' inline-block h-[1px] w-full  bg-black'></span>
                            }
                        </div>
                        <div className=' flex items-center'>
                            <div className={`${path ? "w-28" : "w-40"}`}>
                                <span className='text-[12px] font-bold'>EMAIL ADDRESS:</span>
                            </div>
                            {
                                clientData && clientData.email ?
                                    <span className=' font-semibold  text-[11px]'>{clientData.email}</span>
                                    :
                                    <span className=' inline-block h-[1px] w-full  bg-black'></span>
                            }
                        </div>
                    </div>
                    <p className=' text-[9px] font-semibold mt-10 text-center'>Hereinafter referred to as the Client; which expression, unless repugnant to the subject or context, shall include their successors,executors, legal representatives and assigns of the Parties of the Second Part</p>
                    <div className=' mt-10 text-center'>
                        {
                            clientData && clientData.consentDate ?
                                <p className=' font-bold text-[12px]'>DATE: {new Date(clientData.consentDate).toDateString()} </p>
                                :
                                <p className=' font-bold text-[12px]'>DATE: [Day], [Date] [Month], [Year]</p>

                        }
                    </div>
                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 1 end */}
                {/* section 2 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />
                    <p className=' leading-7'>This <span className=' font-bold'>Consulting Services Agreement</span> (hereinafter the <span className=' font-bold'>“Contract”</span>) is made effective on the
                        {
                            clientData ?
                                <span className=' font-bold'> {new Date(clientData.concernEffectOnDate).getDate()} </span>
                                :
                                <span>___</span>
                        }
                        day of the month of
                        {
                            clientData ?
                                <span className=' font-bold'> {monthNames[new Date(clientData.concernEffectOnDate).getMonth()]} </span>
                                :
                                <span>____________</span>
                        }
                        , (
                        {
                            clientData ?
                                <span className=' font-bold'>{toWords.convert(new Date(clientData.concernEffectOnDate).getFullYear())}</span>
                                :
                                <span className=' font-bold'>Year in Words</span>
                        }


                        ) of the Christian era (hereinafter the “Effective Date”).</p>
                    <p className=' font-bold my-3 text-sm underline text-green-500 text-center'>BY AND BETWEEN,</p>
                    <div className='leading-5'>
                        <p ><span className=' font-bold'>GIC Education Limited,</span> a study abroad consultancy company (hereinafter the “Consultant”) and [Mr. /
                            Ms. / Mrs.] [Full Name of Client] (hereinafter the <span className=' font-bold'>“Client”</span>), the recipient of the study abroad consultancy
                            services from the <span className=' font-bold'>Consultant</span>, in accordance to the terms, and conditions included in the <span className='font-bold'>Contract</span>.
                        </p>
                        <p className=' mt-2'>Hence, the <span className=' font-bold'>Consultant</span> is the <span className=' font-bold'>“First Party”</span> and the Client is the <span className=' font-bold'>“Second Party”</span> and collectively the
                            Consultant and the Client are the <span className=' font-bold'>“Parties”</span> of this <span className=' font-bold'>Contract</span>.
                            .</p>
                        <p className=' mt-2'>The <span className=' font-bold'>Contract</span> includes confidential information of both Parties, intended only for the use by the
                            <span className=' font-bold'> Consultant</span>, or any of its authorized employees, delegates, representatives or legal counsel as and when
                            required, and the <span className=' font-bold'>Client</span> only, and; in the event of an untimely demise of the Client, his or her heir(s) or
                            legal representatives.
                        </p>
                        <p className=' mt-2'>In the instance when the mentioned individual is a minor on the day of signing of this Contract, this
                            document shall be provided to his/her biological parent, or legal guardian, who shall be the signatory on
                            behalf of the minor to ensure this Contract remains valid and enforceable for the duration of the
                            services rendered, stipulated by the terms and conditions stated within this document</p>
                    </div>
                    <p className=' font-bold my-5 text-sm underline text-green-500 text-center'>SERVICES</p>
                    <div>
                        <p className='text-[12px] leading-5'>The <span className=' font-bold'>Consultant</span> is expected to provide the <span className=' font-bold'>Services</span> stated in <span className=' font-bold'>Appendix</span> to the <span className=' font-bold'>Client</span> in accordance to the terms and
                            conditions of this <span className=' font-bold'>Contract</span>. The <span className=' font-bold'>Services</span> have been, according to natural progression and requirement, been
                            separated into steps to ensure transparency of the rendered <span className=' font-bold'>Services</span> as well as to avoid any undue burden or
                            liability for <span className=' font-bold'>either</span> Parties. The applicable steps of receiving the services for the <span className=' font-bold'>Client</span> from the <span className=' font-bold'>Consultant</span>, and for
                            receiving the entitled compensation for the <span className=' font-bold'>Consultant</span> from the <span className=' font-bold'>Client</span> is outlined clearly in the <span className=' font-bold'>Appendix</span> of this
                            Contract.</p>
                        <p className=' font-bold mt-5'>Therefore, both Parties have understood the contents and stipulations stated in the Appendix.</p>
                    </div>
                    <p className=' font-bold my-5 text-sm underline text-green-500 text-center'>TERMS AND CONDITIONS</p>
                    <div>
                        <p className='text-[12px] leading-5'>The <span className=' font-bold underline'>terms and conditions</span> included and outlined within this Contract shall be held as the doctrine of the operations
                            of both the <span className=' font-bold'>Parties</span>, to successfully uphold individual responsibilities, participation abiding by any contingency or
                            stipulation stated at the time of signing of this <span className=' font-bold'>Contract</span>.</p>
                        <p className=' mt-3'>The <span className=' font-bold'>terms and conditions</span> shall apply to both Parties. This <span className=' font-bold'>Contract</span> is, in essence, to protect the rights of both <span className=' font-bold'>Parties</span> as mutually agreed upon at the time of signing this Contract by both <span className=' font-bold'>Parties</span>.</p>
                        <p className=' mt-3'>Hence, neither of the <span className=' font-bold'>Parties</span> may reasonably expect or claim inclusion of or relief from the terms and conditions
                            of this <span className=' font-bold'>Contract</span> unless it is made void either by execution of dissolution of the <span className=' font-bold'>Contract</span> in writing with the consent of both <span className=' font-bold'>Parties</span> with identifiable witnesses or due to failure of compliance to the terms and conditions of this <span className=' font-bold'>Contract</span> by either of the <span className=' font-bold'>Parties</span> individually. the terms and conditions included in this Contract govern both the operation and your participation in the service(s) stated.</p>
                    </div>

                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 2 end */}
                {/* section 3 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />
                    <p className=' font-bold mb-5 text-sm underline text-green-500 text-center'>DOCUMENTS</p>
                    <p className=' mt-5 font-bold underline italic'>The following documents mentioned hereto shall be deemed to form an integral part of this Contract:</p>

                    <ul className=' list-disc pl-5'>
                        <li>
                            <p className=' text-red-600 font-bold'>Appendix:</p>
                            <p className=' font-bold'> The first attached Appendix document shall be referred to as Appendix-A.</p>
                            <p>The Appendix (either singular or multiple, whichever is applicable) shall contain the official document
                                provided by the <span className=' font-bold'>Consultant</span> to the <span className=' font-bold'>Client</span> regarding the specific <span className=' font-bold'>Services</span> which the <span className=' font-bold'>Client</span> is to receive from the <span className=' font-bold'>Consultant</span>, along with the relevant compensation the <span className=' font-bold'>Consultant</span> is to receive from the <span className=' font-bold'>Client</span> in exchange for
                                the <span className=' font-bold'>Services</span> provided, abiding by any and all contingencies, stipulations, limitations or force majeure which
                                may affect the outcome or execution of the responsibilities of the <span className=' font-bold'>Consultant</span> and the <span className=' font-bold'>Client</span>. Each party is to be responsible for fulfilling its own responsibilities and shall not be liable for the other party’s failure to
                                comply with the terms and conditions of this Contract.</p>

                            <p className=' mt-2'>The country-specific step-by-step consulting services related to availing study abroad opportunities for the
                                <span className=' font-bold'> Client</span>. The document shall be attached as an <span className=' font-bold'>Appendix</span> to this <span className=' font-bold'>Contract</span> at the time of signing. The <span className=' font-bold'>Appendix</span>
                                shall outline any and all services the <span className=' font-bold'>Client</span> may expect to receive from the <span className=' font-bold'>Consultant</span> as well as any and all responsibilities of the <span className=' font-bold'>Client</span> towards the <span className=' font-bold'>Consultant</span> for such services to be rendered in favor of the <span className=' font-bold'>Client</span>.</p>


                            <p className=' mt-2'>Both parties hereby agree that they have mutually discussed and possess and equally clear understanding of
                                the <span className=' font-bold'>Services</span> which may be expected by the <span className=' font-bold'>Client</span> from the <span className=' font-bold'>Consultant</span> and the responsibilities of the Client in cooperating, participating and fulfilling all applicable responsibilities including but not limited to the stated compensation owed to the <span className=' font-bold'>Consultant</span> by the <span className=' font-bold'>Client</span> according to the specified structure of Services and payment structure stated in the country specific <span className=' font-bold'>Appendix</span> of this <span className=' font-bold'>Contract</span>.</p>


                            <p className=' mt-2'>If more than one country is included in the <span className=' font-bold'>Services</span> at the time of signing of this Contract, each individual
                                country shall have a separate document annexed to this <span className=' font-bold'>Contract</span>, chronologically labelled in alphabetic
                                manner as <span className=' font-bold'>Appendix-B and onwards</span>. The contents of each individual <span className=' font-bold'>Appendix</span> document may not entitle the same services, or financial bindings for the <span className=' font-bold'>Client</span>.</p>
                        </li>
                        <li className=' mt-2'>
                            <p className=' text-red-600 font-bold'>Documents provided by the Client:</p>
                            <p className=' font-bold'>The client MUST provide all relevant personal documents within 5 days of the Execution Date of the
                                Contract, unless mutually agreed upon by both Parties with reasonable cause.</p>
                            <p>
                                <p className=' font-bold underline'>Personal Documents of the Client includes but may not be limited to the Client’s:</p>
                                <ul className=' pl-5 list-disc'>
                                    <li>Valid Birth Certificate</li>
                                    <li>Valid Passport</li>
                                    <li>Valid National ID Card</li>
                                    <li>Scan copies of all academic achievements including official Certificates and Transcripts</li>
                                    <li>Scan copy or copies of any English Proficiency Test Certificate (if applicable)</li>
                                    <li>Any other document or documents of the <span className=' font-bold'>Client</span> deemed to be relevant and or necessary by the <span className=' font-bold'>Consultant</span> for the purpose of providing the <span className=' font-bold'>Services</span> in a timely and accurate manner.</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <p className=' text-red-600 font-bold'>Miscellaneous Documents:</p>
                            <p className=' font-bold'>Any and or all other documentation as and when required relating to the Services, including but not limited
                                to, on the Client’s part, sharing of personal, confidential, financial information of the Client and any other
                                person related to the Client by way of familial relationships, guardians, sponsors or dependents.</p>
                            <p>
                                Documents, as and when required may be prepared by third parties on behalf of the <span className=' font-bold'>Client</span> by employees and
                                or enlisted vendors authorized by the <span className=' font-bold'>Consultant</span>. Such documents may include legal declarations such as
                                affidavits, promissory notes, financial commitments as required for the execution of the <span className=' font-bold'>Services</span> of the
                                <span className=' font-bold'>Contract</span>.</p>
                        </li>
                    </ul>
                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 3 end */}
                {/* section 4 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />
                    <div>
                        <p className=' font-bold mb-5 text-sm underline text-green-500 text-center'>CONSENT FOR RELEASE OF INFORMATION</p>
                        <p className=' leading-5'>The <span className=' font-bold'>Client</span> hereby understands, agrees and consents to the release of their personal information or a child’s
                            personal information (including sensitive information) which is shared by the <span className=' font-bold'>Client</span> with the <span className=' font-bold'>Consultant</span> to any
                            authorized third-party individual or entity assigned by the <span className=' font-bold'>Consultant</span> nationally and or internationally strictly for
                            the purpose of providing the <span className=' font-bold'>Services</span> to the <span className=' font-bold'>Client</span> as per the terms and conditions of this <span className=' font-bold'>Contract</span>.</p>
                    </div>
                    <div>
                        <p className=' font-bold my-5 text-sm underline text-green-500 text-center'>CONFIDENTIALITY & PROPRIETARY INFORMATION:</p>
                        <p className=' underline font-bold'>Assignment and Delegation</p>
                        <p className=' mt-1'>
                            The <span className=' font-bold'>Consultant</span> may assign rights and may delegate duties under this <span className=' font-bold'>Contract</span> to other individuals or entities
                            acting as a subcontractor (<span className=' font-bold'>&quot;Subcontractor&quot; or “Third Party”</span>). The <span className=' font-bold'>Consultant</span> recognizes that they shall be liable for all work performed by the <span className=' font-bold'>Subcontractor</span> or <span className=' font-bold'>Third Party</span> and shall hold the Client harmless of any liability in
                            connection with their performed work.</p>
                        <p className=' mt-2 '>The <span className=' font-bold'>Consultant</span> shall be responsible for any confidential or proprietary information shared with the Subcontractor
                            in accordance with this section. If any such information is misrepresented or used (without related purpose,
                            misused or used with commercial interest unrelated to the Services of this Contract by any <span className=' font-bold'>Subcontractor</span>, the
                            <span className=' font-bold'> Consultant</span> shall have full responsibility of rectifying the matter accordingly.</p>
                    </div>
                    <div className='text-start'>
                        <p className=' font-bold my-5 text-sm underline text-green-500 text-center'>COMPENSATION</p>
                        <p className=' leading-5'>In order to receive the services entitled by The <span className=' font-bold'>Client</span> from the <span className=' font-bold'>Consultant</span>, the <span className=' font-bold'>Client</span> must ensure to provide in full
                            the mutually agreed upon compensation to the <span className=' font-bold'>Consultant</span> for each of the steps in accordance to the stipulations
                            mentioned in the <span className=' font-bold'>Appendix</span>. The compensation must be paid in full by the <span className=' font-bold'>Client</span> to the <span className=' font-bold'> Consultant</span> in accordance
                            to the mutually agreed upon payment structure stated in the <span className=' font-bold'>Appendix</span> of the <span className=' font-bold'>Contract</span>.
                        </p>
                        <p className=' mt-5 leading-5'>For reference, the table below outlines the applicable compensation entitled by the <span className=' font-bold'>Consultant</span> from the <span className=' font-bold'>Client</span> for
                            the applicable <span className=' font-bold'>Services</span> rendered:</p>
                        <table className=' w-full mt-5'>
                            <thead>
                                <tr className=' border border-black'>
                                    <th colSpan={5} className="py-1 bg-green-100 text-center ">PAYMENT STRUCTURE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=' border border-black'>
                                    <td className=' bg-green-100 py-1 text-center font-bold px-1 border-r border-black'>COUNTRY</td>
                                    <td className=' bg-gray-100 py-1 text-center font-bold px-1 border-r border-black'>FIRST STEP</td>
                                    <td className=' bg-gray-100 py-1 text-center font-bold px-1 border-r border-black'>SECOND STEP</td>
                                    <td className=' bg-gray-100 py-1 text-center font-bold px-1 border-r border-black'>THIRD STEP</td>
                                    <td className=' bg-gray-100 py-1 text-center font-bold px-1'>FINAL STEP</td>
                                </tr>
                                <tr className=' border border-black'>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>CANADA</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 180 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 180 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>N/A</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 1000 USD</td>
                                </tr>
                                <tr className=' border border-black'>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>USA</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 180 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 180 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>N/A</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 1000 USD</td>
                                </tr>
                                <tr className=' border border-black'>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>SWEDEN</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 180 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 180 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 500 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 800 USD</td>
                                </tr>
                                <tr className=' border border-black'>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>VISA SUPPORT</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 350 USD</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>N/A</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>N/A</td>
                                    <td className=' border-r border-black py-px px-1 text-center font-bold'>$ 650 USD</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className=' mt-5 '>
                            <p>The above payment stacture <span className=' font-bold underline'>includes:</span></p>
                            <div className=' flex gap-1'>
                                <span>l.</span>
                                <p>Document Translation costs</p>
                            </div>
                            <div className=' flex gap-1'>
                                <span>ll.</span>
                                <p>Documentation Notarization cost</p>
                            </div>
                            <div className=' flex gap-1'>
                                <span>ll.</span>
                                <p>Documentation courier costs and up to four university application fees <span className=' text-red-500 font-semibold'>(Only for Sweden)</span></p>
                            </div>
                        </div>
                        <div className=' mt-5'>
                            <p>The above payment stacture <span className=' underline font-bold'>dose not include:</span></p>
                            <div className=' flex gap-2'>
                                <span>l.</span>
                                <div>
                                    <p>Any applicable APPLICTION FEES for foreign education institutions</p>
                                    <p>Any applicaable costs of ENGLISH LANGUAGE PROFICIENCY TESTS as may be required as a pre-requisite for eligibility.</p>
                                </div>
                            </div>
                            <div className=' flex gap-1'>
                                <span>ll.</span>
                                <p>Documentation Notarization cost</p>
                            </div>
                            <div className=' flex gap-1'>
                                <span>ll.</span>
                                <p>Any applicable cost of additional evaluations or as may be required by foreign education institutions.</p>
                            </div>
                        </div>
                    </div>
                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 4 end */}
                {/* section 5 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />
                    <div>
                        <p><span className=' italic'>Any embassy fees, visa application fees, additional country specific immigration requirement fees such as “Study
                            Permit”, “Biometric Fee”, “Medical Fee”, “Open Work Permit”, “SEVIS Fee”, “USA Embassy Interview Fee” and “Air
                            Ticket Fee”.</span> <br />

                            The <span className=' font-bold'>Services</span> as per the terms and conditions of the <span className=' font-bold'>Contract</span> shall be executed according to a structure set by the <span className=' font-bold'>Consultant</span> according to the <span className=' font-bold'>Consultant’s</span> preferred operating protocol. This structure may be changed permanently and or temporarily by the <span className=' font-bold'>Consultant</span> according to its preference. However, the Client shall remain
                            bound by the structure of services rendered by the <span className=' font-bold'>Consultant</span> provided to the Client by the Consultant on the <span className=' font-bold'>Execution Date</span> of the <span className=' font-bold'>Contract unless any change is permitted by the Consultant</span> which refrains from any
                            negative impact on the services entitled to the <span className=' font-bold'>Client</span>.</p>

                        <p className=' mt-2'>The services the Client shall receive from the Consultant for each step of the process contingent on the Client
                            depositing the compensation installment in full to the Consultant within the stipulated time is stated clearly in the
                            <span className=' font-bold'>Appendix</span> of the <span className=' font-bold'>Contract</span>, which both <span className=' font-bold'>Parties</span> hereby declare to be informed of and acknowledge each parties’
                            individual responsibilities</p>


                        <p className=' font-bold underline my-5'>RULES OF DEPOSITING COMPENSATION</p>
                        <p>All payments made by the Client to the Consultant <span className=' font-bold underline text-[11px]'>MUST</span> only be deposited using the <span className=' font-bold'>OFFICIAL PAYMENT GATEWAYS</span> of the <span className=' font-bold'>Consultant</span>. The <span className=' font-bold'>Consultant</span> shall provide the <span className=' font-bold'>Client</span> a digital copy of its official payment guidelines stating all available payment method options and procedures.</p>


                        <p className=' mt-2'>The <span className=' font-bold'>Client</span> will receive an official invoice from the <span className=' font-bold'>Consultant</span> upon every payment made by the <span className=' font-bold'>Client</span> in favor of
                            the <span className=' font-bold'>Consultant</span> so long as the payment is made by the <span className=' font-bold'>Client</span> according to the payment guidelines provided by the
                            <span className=' font-bold'>Consultant</span> along with the digital copy of the <span className=' font-bold'>Contract</span> on the <span className=' font-bold'>Execution Date.</span></p>

                        <p className=' mt-2'>All entitled compensations of the <span className=' font-bold'>Consultant</span> must be paid in <span className=' font-bold'>Bangladeshi Taka (BDT)</span> by the Client.</p>

                        <p className=' mt-2'>The amounts stated in the above table are in <span className=' font-bold'>United States Dollar [USD ($)]</span>. The equivalent amount in
                            <span className=' font-bold'> Bangladeshi Taka (BDT)</span> may vary depending on the foreign currency exchange rates in Bangladesh on the date of
                            payment by the <span className=' font-bold'>Client</span>.</p>
                    </div>
                    <div>
                        <p className=' my-5 text-green-500 underline text-center font-bold'>REFUND POLICY FOR PAYMENT</p>
                        <p>The Client shall be entitled to a 100% Refund of the First Step Payment of $180 USD equivalent in BDT made in
                            favor of the Consultant ONLY if the Client’s preliminary assessment results in negative feedback by the Consultant
                            and or any of its authorized employees, representatives, partners, delegates and or advisors, whether national or
                            international.</p>
                        <p className=' mt-3'>In the event the Client signs the Contract with the Consultant on the Execution Date and decides to not continue
                            the Contract with the Consultant prior to the execution of the services included in the First Step of the Services
                            rendered by the Consultant, the Client shall be eligible to 70% refund of the First Step Payment of $180 USD
                            equivalent in BDT made in favor of the Consultant by the Client upon approval by the concerned departments of
                            the Consultant’s entity, so long as no other cause negates the Client’s eligibility for the refund.</p>
                        <p className=' mt-3'>If the services rendered in the First Step to the <span className=' font-bold'>Client</span> by the <span className=' font-bold'>Consultant</span> reflects positive results as per the mutually
                            agreed upon terms and conditions of the <span className=' font-bold'>Contract</span>, the <span className=' font-bold'>Client</span> shall not be eligible for any refund of the First Step
                            Payment of $180 USD equivalent in BDT made in favor of the Consultant by the Client.</p>
                    </div>
                    <div>
                        <p className=' my-5 text-green-500 underline text-center font-bold'>TERM &amp; SEVERABILITY</p>
                        <p>The term of validity of the <span className=' font-bold'>Contract</span> shall be deemed to be 365 days (or 1 Year) from the <span className=' font-bold'>Execution Date</span> of the Contract.</p>
                        <p className=' mt-2'>Hence, the <span className=' font-bold'>Services</span> to be rendered by the <span className=' font-bold'>Consultant</span> to the <span className=' font-bold'>Client</span> shall commence to be active from the
                            <span className=' font-bold'>Execution Date</span> of the <span className=' font-bold'>Contract</span> and the <span className=' font-bold'>Contract</span> and the <span className=' font-bold'>Services</span> shall accordingly, automatically be terminated
                            upon completion of <span className=' font-bold underline'>365 Days</span> from the <span className=' font-bold'>Execution Date.</span></p>
                    </div>
                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 5 end */}
                {/* section 6 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />

                    <div className=' mt-2'>
                        <div>
                            <p className=' mt-2'>The duration of time within which the <span className=' font-bold'>Client</span> shall be entitled to receive the <span className=' font-bold'>Services</span> from the <span className=' font-bold'>Consultant</span> in accordance to the Terms &amp; Conditions of this <span className=' font-bold'>Contract</span> shall be a period of 365 days (Three Hundred &amp; Sixty-Five
                                Calendar Days) from the <span className=' font-bold'>Execution Date</span> of the <span className=' font-bold'>Contract</span>. If an extension of time is required by the <span className=' font-bold'>Client</span> to continue receiving the <span className=' font-bold'>Services</span> from the <span className=' font-bold'>Consultant</span>, a new contract (in which terms and conditions may vary) will
                                be required to be signed again after expiration of the stipulated time stated.</p>

                            <p className=' font-bold mt-2 text-[11px]'>If the Client wishes to continue to receive the Consultant’s services after completion of the 365 days period, the
                                Client will mandatorily be required to sign a new contract to receive the Consultant’s services. In the event a
                                new contract is required, the terms and conditions of the new contract may not include the same terms and
                                conditions of the previous Contract between the Parties.</p>

                            <p className=' font-bold mt-2 text-[11px]'>If all the Services of the Contract is provided by the Consultant to the Client within the validity of the Contract,
                                then the Contract shall be deemed to have been executed, so long as both Parties have fulfilled their
                                responsibilities accordingly and in full.</p>
                        </div>
                        <p className=' underline font-bold pb-1 mt-2'>Cancellation of the Contract</p>
                        <p>The <span className=' font-bold'>Consultant</span> may decide, with reasonable cause, to terminate the Contract with the Client without prior notice
                            at any time if repeated lack of cooperation from the Client may be confirmed by the Consultant and or any of its
                            employees or authorized representatives. Lack of cooperation for the Client may result from, but may not be
                            limited to, the lack of communication, participation, transparency, ethics, failure to abide by the terms and
                            conditions of the stipulations of the <span className=' font-bold'>Contract</span>, or any other act performed, expected to be performed, claimed to
                            have been performed and / or forecasted to be performed by the <span className=' font-bold'>Client</span> which threatens, tarnishes or attempts to
                            devalue the reputation of the <span className=' font-bold'>Consultant</span> in public view, whether within the country of Bangladesh, and or
                            internationally, which exerts the impression of lack of good faith of the <span className=' font-bold'>Client</span> on the <span className=' font-bold'>Consultant</span>, this <span className=' font-bold'>Contract</span>, and / or portrays, indicates or proves any ill intent towards the <span className=' font-bold'>Consultant</span> from the <span className=' font-boldClient'>Client</span>.</p>
                    </div>
                    <div className=' mt-2'>
                        <p className=' underline font-bold pb-1'>Cancellation of Contract Resulting in the Client to be Blacklisted by the Consultant</p>

                        <p>This Agreement shall remain in effect in the event a section or provision is unenforceable or invalid. All remaining
                            sections and provisions shall be deemed legally binding unless:</p>
                        <ul className=' pl-10 list-disc mt-2'>
                            <li>
                                <p>The Consultant, in good faith, must act to terminate this <span className=' font-bold'>Contract</span> resulting from misconduct or failure to
                                    comply to the terms and conditions of this agreement by the <span className=' font-bold'>Client</span> by way of violation of the terms and
                                    conditions in accordance to this Contract, and may result in being ‘blacklisted’ as a <span className=' font-bold'>Client</span> of the
                                    <span className=' font-bold'>Consultant’s</span> area of service. Redacting any and / or all rights entitled by the Client in accordance to the
                                    terms and conditions of this <span className=' font-bold'>Contract</span>. Furthermore, any such violation may result in legal proceedings to
                                    be initiated by the Consultant to seek refuge of law to prevent undue harm, harassment or defamation
                                    attempted to or performed by the <span className=' font-bold'>Client</span> towards the Consultant.</p>
                            </li>
                            <li>
                                <p>Any sort of repeated misbehavior, disrespect, misconduct, immoral act, harm or use of influence used,
                                    performed or threatened to be performed by the Client against the Consultant and or any of its
                                    employees and or authorized representatives with or without viable cause shall result in termination of
                                    the Contract by the Consultant.</p>
                            </li>
                        </ul>
                        <p className=' font-bold mt-2 text-[11px]'>In the event the Contract is terminated by the Consultant due to the above-mentioned reasons, the Client shall
                            not be entitled to receive any refunds of the payments made in part or full in favor of the Consultant as per the
                            terms and conditions of the Contract.</p>


                        <p className=' my-5 text-green-500 underline text-center font-bold'>PROFILE ASSESSMENT AND SUGGESTED COURSE OPTIONS</p>
                        <p className=' mt-3'>The <span className=' font-bold'>Client</span> hereby acknowledges and agrees that the option(s) for courses and academic institutions provided to the <span className=' font-bold'>Client</span> after conducting a thorough assessment of the <span className=' font-bold'>Client's</span> profile by the <span className=' font-bold'>Consultant</span> and its representatives will be done so in a manner to ensure not only academic eligibility, but various other variables which may, from case to case, affect the overall probability of a successful outcome for the client not only for securing admission, but also aligned with optimum chances of a positive visa outcome and onwards progressions. Therefore, the <span className=' font-bold'>Client</span> thereby acknowledges and agrees to choose from the option(s) provided by the <span className=' font-bold'>Consultant</span>, according to their preference and advise of the <span className=' font-bold'>Consultant</span> and / or their representatives.</p>

                        <p className=' mt-3'>The <span className=' font-bold'>Client</span> hereby acknowledges and agrees that certain prerequisites directly impact eligibility and successful outcome of academic applications and visa decisions. Therefore, if the <span className=' font-bold'>Client</span>, at the time of conducting their profile assessment does not have the prerequisites completed as per the variable requirements from academic institutions and concerned country-specific guidelines suggested by the Immigration authorities, the <span className=' font-bold'>Consultant</span> (or its representatives) will inquire regarding predicted outcomes of the mandatory prerequisites and the timeline within which it must be completed prior to conducting the profile assessment. The <span className=' font-bold'>Client's</span> profile assessment will therefore be conducted based on recommended outcomes by <span className=' font-bold'>Consultant</span> on part of the <span className=' font-bold'>Client's</span> mandatory prerequisites which are not completed at the time of the <span className=' font-bold'>Client's</span> profile assessment. The <span className=' font-bold'>Client</span> shall be solely responsible to ensure the outcomes agreed upon by the <span className=' font-bold'>Client</span> and <span className=' font-bold'>Consultant</span> prior to the profile assessment, and the <span className=' font-bold'>Consultant</span> shall bear no responsibility if the <span className=' font-bold'>Client</span> is unable to do so at a later stage of their file processing; and the <span className=' font-bold'>Client</span> shall also not be eligible for refund in such cases.
                        </p>

                        <p className=' mt-3'>The <span className=' font-bold'>Client's</span> profile assessment report conducted by the <span className=' font-bold'>Consultant</span> based on relevant information provided by the <span className=' font-bold'>Client</span> shall be considered accurate, final and true for the purpose of conducting the Client's profile assessment. The <span className=' font-bold'>Consultant</span> shall thereby bear no responsibility if the information provided by the <span className=' font-bold'>Client</span> to the <span className=' font-bold'>Consultant</span> prior to the profile assessment is found to be inaccurate at a later time which may impact the outcome of the <span className=' font-bold'>Client's</span> file processing.</p>

                        <p className=' mt-3'>The <span className=' font-bold'>Client</span> hereby agrees to be solely responsible for providing all mandatory prerequisites such as personal or academic documentation, reference letters, employment documents, financial documents, payments of application, tuition and embassy fees, and decisions on the <span className=' font-bold'>Client's</span> part within any stipulated deadlines suggested by the <span className=' font-bold'>Consultant</span>, its representatives and / or relevant academic or immigration authorities to ensure execution of tasks with time-specific deadlines. In cases where the <span className=' font-bold'>Consultant</span> has informed the client prior to the deadline regarding such requirements to be fulfilled by the <span className=' font-bold'>Client</span>, and the <span className=' font-bold'>Client</span> fails to do so, the <span className=' font-bold'>Consultant</span> may not be held responsible for any impact it may cause to the <span className=' font-bold'>Client's</span> file processing.</p>
                    </div>
                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 6 end */}
                {/* section 7 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />
                    <div className=' mt-3'>
                        <p className=' my-5 text-green-500 underline text-center font-bold'>INTELLECTUAL PROPERTY AND RIGHTS TO DOCUMENTATION</p>

                        <p className=' font-bold text-[11px]'>The Client shall retain all rights to the personal documents shared by the Client with the Consultant to receive the Services in accordance to the Contract between the Parties. Such documentation may also be retained by the Consultant without limitations to the validity of the Contract between the Parties.</p>
                        <p className=' font-bold mt-1 text-[11px]'>The Consultant shall retain all rights to the documents prepared by the Consultant or any of its authorized representatives or third party contractors. Any such document may be reviewed by the <span className=' font-bold'>Client</span> within the premises of the Consultant’s local offices. Such documentation may also be retained by the Consultant without limitations to the validity of the Contract between the Parties.</p>
                    </div>
                    <div className=' mt-2'>
                        <p className=' mt-0 mb-5 text-green-500 underline text-center font-bold'>DISCLAIMER</p>
                        <p className=' font-bold text-[11px] '>Any guidance, samples, paperwork, strategy or relevant information to the Services of the Contract which is shared in confidence by the
                            Consultant with the Client, with the sole intention of providing the Services of the Contract to the Client MUST remain confidential between
                            both the Parties. Sharing of any such information, whether unintentionally or intentionally, with or without malicious intent, with or
                            without commercial intent, with or without financial interests shall result in a violation of the terms and conditions of the Contract by the
                            Client.</p>
                        <p className=' font-bold text-[11px] mt-2'>In such an event, the Consultant may terminate the Contract with the Client without prior notice and the Client may be held liable in a court
                            of law in accordance to the laws of governance of Bangladesh and or any other country where the Services of the Contract involves.</p>
                        <div className=' mt-2'>
                            <p className=' my-5 text-green-500 underline text-center font-bold'>NOW THEREFORE, THE PARTIES HERETO HEREBY AGREE AS FOLLOWS:</p>
                            <div className=' flex gap-2'>
                                <span className=' font-bold'>1.</span>
                                <p>In the event of any inconsistency regarding any documents; personal, academic, professional, identity,
                                    financial or otherwise; provided by the Client, as mandatorily required by the Consultant from the Client, for
                                    timely and accurate consultancy services, deemed to be intentional, which may cause hindrance to the
                                    <span className=' font-bold'>Consultant</span> in providing proper services to the Client, as entitled in this <span className=' font-bold'>Contract</span>, the <span className=' font-bold'>Consultant</span> shall not be
                                    held liable for any outcome resulting from the use of such documentation.</p>
                            </div>
                            <div className=' flex gap-2 my-2'>
                                <span className=' font-bold'>2.</span>
                                <p>The <span className=' font-bold'>Consultant</span> shall, in good faith, accept any and all required documentation from the <span className=' font-bold'>Client</span>, to ensure
                                    providing to the Client, timely and accurate consultancy services as per the terms and conditions of this
                                    contract.</p>
                            </div>
                            <div className=' flex gap-2 my-2'>
                                <span className=' font-bold'>3.</span>
                                <p>If the <span className=' font-bold'>Consultant</span> shall require further documentation from the <span className=' font-bold'>Client</span>; to conduct any form of background checks of the information and / or documentation provided to the <span className=' font-bold'>Consultant</span> by the <span className=' font-bold'>Client</span>, either as digital copy or hard copy; in order to authenticate, verify or validate the information included in such documentation by itself, any third party individual or entity, or law enforcement; with the intent of upholding integrity of the specialized services provided by the Consultant and / or to ensure providing timely and accurate services to the Client, as per the terms and conditions of this agreement. The Client hereby, in a sound state of mind, acknowledges the <span className=' font-bold'>Consultant’s</span> lawful requirement to provide the consultancy services in a lawful manner in accordance to the rules, regulations, laws, by-laws, and integrity of operations as a consultancy business entity.</p>
                            </div>
                            <div className=' flex gap-2 my-2'>
                                <span className=' font-bold'>4.</span>
                                <p>Therefore, the <span className=' font-bold'>Client</span>, in a sound state of mind, agrees to take full and complete responsibility or the impacts
                                    or outcomes relating to the consultancy services provided by the Consultant to the Client resulting from the
                                    use of any or all information, documentation and identification provided by the Client to the Consultant, to be
                                    used for the <span className=' font-bold'>Consultancy</span> services entitled to the Client, from the Consultant, as per the terms and conditions
                                    of this <span className=' font-bold'>Contract</span>.</p>
                            </div>
                            <div className=' flex gap-2 my-2'>
                                <span className=' font-bold'>5.</span>
                                <div>
                                    <p className=' my-1'>The terms and conditions mentioned in this <span className=' font-bold'>Contract</span> shall remain valid indefinitely unless:</p>
                                    <div className=' flex gap-1'>
                                        <span>l.</span>
                                        <p>both parties, provide in writing, their consent to annulment of this <span className=' font-bold'>Contract</span>; or</p>
                                    </div>
                                    <div className=' flex gap-1'>
                                        <span>ll.</span>
                                        <p>if either party fails to uphold their obligatory bindings expected to be performed, in which case, the
                                            other party may hold any or all terms and conditions claimed to have been breached, with evidence
                                            supporting the same, by the other party as a valid reason to make this <span className=' font-bold'>Contract</span> void.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer clientData={clientData} />
                </div>
                <hr className=' mt-10 print:hidden' />
                {/* section 7 */}
                {/* section 8 start */}
                <div className=' px-14 print:px-20 text-[12px] break-after-page relative text-justify'>
                    <Header />
                    <div className=' mt-2'>
                        <div>
                            <p className=' mt-2 mb-3 text-green-500 underline text-center font-bold'>EMPLOYEES&#39; COMPENSATION</p>
                            <p>The <span className=' font-bold'>Consultant</span> shall be solely responsible for the following:</p>
                            <ul className=' pl-5 list-disc'>
                                <li className=' mt-1'>
                                    <p><span className=' font-bold'>Employee Benefits</span>. The <span className=' font-bold'>Consultant</span> understands and agrees that they are solely responsible and shall be
                                        liable to all benefits that are provided to their employees, including, but not limited to, retirement plans,
                                        health insurance, vacation time-off, sick pay, personal leave, or any other benefit provided.</p>
                                </li>
                                <li className=' mt-1'>
                                    <p><span className=' font-bold'>Unemployment Compensation</span>. The <span className=' font-bold'>Consultant</span> shall be solely responsible for the unemployment
                                        compensation payments on behalf of their employees and personnel. The <span className=' font-bold'>Consultant</span> shall not be entitled
                                        to unemployment compensation with the Services included in <span className=' font-bold'>Appendix-A</span> of this <span className=' font-bold'>Contract</span>.</p>
                                </li>
                                <li className=' mt-1'>
                                    <p><span className=' font-bold'>Workers’ Compensation.</span> The <span className=' font-bold'>Consultant</span> shall be responsible for providing all workers&#39; compensation insurance on behalf of their employees. If the <span className=' font-bold'>Consultant</span> hires employees to perform any work under this
                                        <span className=' font-bold'>Contract</span>, the  <span className=' font-bold'>Consultant</span> agrees to grant workers&#39; compensation coverage to the extent required by law. Upon request by the <span className=' font-bold'>Client</span>, the <span className=' font-bold'>Consultant</span> must provide certificates proving workers&#39; compensation
                                        insurance at any time during the performance of the <span className=' font-bold'>Services</span>.</p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p className=' mt-2 mb-3 text-green-500 underline text-center font-bold'>DISPUTES</p>

                            <p className=' font-bold text-[11px]'>Any disputes arising from a disagreement, whether justifiable with reasonable cause or proof or not between the parties
                                shall initially be attempted to be settled amicably between the parties by means of a pre-scheduled in person meeting to
                                discuss possible resolutions to the dispute(s) by the Parties at the Consultant’s office premises.</p>
                            <p className=' mt-2 font-bold text-[11px]'>Failure to resolve such disputes amicably shall result in the Contract to be automatically be considered void and any
                                settlements claimed or demanded shall then be resolved by way of THE ARBITRATION ACT, 2001 of Bangladesh.</p>
                        </div>
                        <div>
                            <p className=' mt-2 mb-3 text-green-500 underline text-center font-bold'>INDEMNITY</p>
                            <p><span className=' font-bold'>The Consultant</span> shall release, defend, indemnify, and hold harmless its employees, authorized representatives,
                                partners, affiliates, third party vendors, agents, and any other concerned participant in the <span className=' font-bold'>Consultant’s</span> business
                                from all legal action, suits, or claims initiated by the <span className=' font-bold'>Client</span> against the <span className=' font-bold'>Consultant</span>.</p>
                            <p className=' mt-1'>Any harm, damage, threat, disrespect, defamation, property damage, infringement of copyright, patent and or
                                other proprietary rights of the <span className=' font-bold'>Consultant</span> brought or attempted to be brought on the <span className=' font-bold'>Consultant</span> or its authorized
                                personnel by the <span className=' font-bold'>Client</span> or any third party, whether known or unknown, acting in favor of the Client against the
                                <span className=' font-bold'>Consultant</span> shall result in legal action against all concerned individuals and entities involved in initiating such
                                actions or threats against the <span className=' font-bold'>Consultant</span>.</p>
                            <p className=' mt-1'>Any and all related legal expenses incurred by the <span className=' font-bold'>Consultant</span> due to such matters shall be rightfully claimed from
                                the <span className=' font-bold'>Client</span>, whether the <span className=' font-bold'>Client</span> was directly involved or not in the process.</p>
                            <p className=' mt-1'>Any incurred injuries or damage, or loss (real or alleged) received or sustained by any person, persons, or property,
                                arising out of services provided under this <span className=' font-bold'>Contract</span> shall remain to be the sole responsibility of the Parties of the <span className=' font-bold'>Contract depending on which party incurs such injury or damage</span>.</p>
                        </div>
                        <div className=' mt-2'>
                            <p className=' text-center text-[10px] font-bold'>Legal Notice:</p>
                            <p className=' text-center text-[9px] font-bold'>All notices required or permitted under this Agreement shall be in writing and shall be deemed delivered when delivered through registered post with return receipt.
                                Two copies of this Contract have been generated. The Client shall be provided with one copy and the Consultant shall retain the other copy. The Contract shall be shared with the client
                                digitally. The Client will receive the Contract which states “Client Copy” and the Consultant shall retain the “Consultant Copy” of the Contract. Both the “Client Copy” and the “Consultant  Copy” of the Contract shall be deemed to be original and shall include the same terms and conditions of the Contract.</p>
                            <p className=' mt-2 text-red-700 font-bold text-[11px] italic'>The Client and the Consultant have undersigned the Contract to provide their consent upon reading and discussion between the parties
                                regarding the terms and conditions of the Contract, and the additional related documents: Appendix and Payment Guideline</p>
                        </div>
                        <div className=' grid items-center grid-cols-2 mt-20'>
                            <div className=' flex '>
                                <div className=' flex flex-col justify-center items-center'>
                                    {
                                        clientData && clientData.clientSignature ?
                                            <>
                                                {
                                                    !uploadSignatureMode ?
                                                        <div className=' w-32 h-24 relative'>
                                                            <div onClick={() => setUploadSignatureMode(true)} className=' flex justify-center items-center  p-1 absolute top-1 right-1 bg-blue-500 text-white rounded-full cursor-pointer'>
                                                                <span className=' text-[10px]'>Change</span>
                                                            </div>
                                                            <img src={`${ImageHost}/${clientData.clientSignature}`} alt='' className=' w-full h-full object-cover' />
                                                        </div>
                                                        :
                                                        <div className=' mb-1 flex'>
                                                            <SignaturePad path={path} clientData={clientData} getUserInfo={getUserInfo} setUploadSignatureMode={setUploadSignatureMode} />
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div className=' mb-1 flex'>
                                                <SignaturePad path={path} clientData={clientData} getUserInfo={getUserInfo} setUploadSignatureMode={setUploadSignatureMode} />
                                            </div>
                                    }
                                    <span className=' w-full h-[1px] bg-black inline-block '></span>
                                    <span className=' font-bold '>SIGNATURE OF CLIENT</span>
                                </div>
                            </div>
                            <div className=' flex justify-end'>
                                <div className=' flex flex-col justify-center items-center'>
                                    {
                                        clientData && clientData.cmId?.cmSignature &&
                                        <div className=' w-32 h-24 mb-1'>
                                            <img src={`${ImageHost}/${clientData.cmId.cmSignature}`} alt='' className=' w-full h-full object-cover' />
                                        </div>
                                    }
                                    <span className=' w-full h-[1px] bg-black inline-block '></span>
                                    <span className=' font-bold '>SIGNATURE OF CONSULTANT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer clientData={clientData} />
                </div>
                {/* section 8 end*/}
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}
