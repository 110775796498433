import React from 'react'

export default function Header() {
    return (
        <div className=' text-center pt-12 pb-5'>
            <p className=' text-red-500 text-[12px] font-bold'>GIC EDUCATION LIMITED</p>
            <p className=' font-bold underline text-[12px]'>CLIENT SERVICE AGREEMENT</p>
        </div>
    )
}
