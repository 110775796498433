import axios from 'axios';
import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Host, ImageHost } from '../../Data';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function SignaturePad({ path, clientData, getUserInfo, setUploadSignatureMode }) {

    const sigCanvas = useRef(null);

    const clearSignature = () => {
        sigCanvas.current.clear();
    };

    const saveSignature = async () => {
        const dataUrl = sigCanvas.current.toDataURL();
        const blob = dataURItoBlob(dataUrl);
        // console.log(blob)
        try {
            if (path) {
                const formData = new FormData()
                formData.append("file", blob, "signature.png")

                if (clientData.clientSignature) {
                    const res = await axios.put(`${ImageHost}/upload/${clientData.clientSignature}`, formData);
                    await axios.post(`${Host}/api/clients/uploadSignature/${path}`, { clientSignature: res.data.filename })
                } else {
                    const res = await axios.post(`${ImageHost}/upload`, formData);
                    await axios.post(`${Host}/api/clients/uploadSignature/${path}`, { clientSignature: res.data.filename })
                }
                getUserInfo()
                setUploadSignatureMode(false)
                toast.success("Signature uploaded")
            }
        } catch (error) {
            toast.error(error.response.data.error)
        }
    };

    // Helper function to convert a data URL to a Blob object
    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    return (
        <div>
            <SignatureCanvas ref={sigCanvas} penColor='black'
                canvasProps={{ width: 300, height: 200, className: 'sigCanvas border border-red-300 mb-2 rounded ' }} />
            <div className=' flex gap-3'>
                <button onClick={clearSignature} className="bg-red-600 text-white text-sm py-1 px-2 rounded shadow">Clear</button>
                <button onClick={saveSignature} className="bg-blue-600 text-white text-sm py-1 px-2 rounded shadow">Save</button>
                {
                    clientData?.clientSignature &&
                    <button onClick={() => setUploadSignatureMode(false)} className="bg-yellow-600 text-white text-sm py-1 px-2 rounded shadow">Close</button>
                }
            </div>
        </div>
    );
}

